import { autoinject, computedFrom, Disposable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { IPlaceSearchItem } from 'services/place-search/models/place-search-item.interface';
import { FeatureEventType } from 'vv-constants/feature-event-type.enum';
import { StorageSiteService } from 'components/common/layer-picker/layer-groups/storage-sites/services/storage-site-service';
import { IStorageSiteContainer } from './models/storage-site-container.interface';

@autoinject
export class StorageSites {
	protected loading = false;
	protected expanded = false;
	protected thisVM = this;

	protected namn: string;
	protected type: number;
	protected items : IStorageSiteContainer[] = [];
	private subscription: Disposable;

	@computedFrom('items.length')
    protected get hasStorages(): boolean {
        return this.items && this.items.length > 0;
    }

	constructor(
		private eventAggregator: EventAggregator,
		private storageSiteService: StorageSiteService) {
        
	}
	
	attached(): void {
        this.subscription = this.eventAggregator.subscribe(FeatureEventType.SHOW_STORAGE_SITES_FOR, (site: IPlaceSearchItem) => {
			this.getStorageSites(site);
			
		});
	}
	deleteItem(siteId: string): void {
        const index = this.items.findIndex(x => x.id == siteId);
        this.items.splice(index, 1);
    }
	detached(): void {
        this.subscription.dispose();
    }

    close(): void {
        this.items = [];
    }

	
	async getStorageSites(site: IPlaceSearchItem): Promise<void> {
		try {
            this.expanded = true;
            this.loading = true;
            const storage = await this.storageSiteService.getStorageSites(site.id);
			
			this.items.push({
				id: site.id, 
				storages: storage, 
				name: site.name, 
				type: site.siteType,
				expanded: true	
			});
            this.loading = false;
        }
        catch (error) {
            this.loading = false;
        }
	}
}