import { autoinject, Disposable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import * as L from 'leaflet';
import { wktToGeoJSON } from "@terraformer/wkt"
import { Position } from 'geojson';
import { DataLayerService } from 'services/data-layer/data-layer-service';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { Proj4GeoJSONFeature } from 'proj4leaflet';
import { MapEventType } from 'vv-constants/map-event-type.enum';
import { IFacitRouteContainer } from 'services/data-layer/models/facit-route-container.interface';
import { IFacitRoute } from 'services/data-layer/models/facit-route.interface';

@autoinject
export class Facitroutes {

	protected facitroutesChecked = false;
	private facitroutesLayerAdded: boolean;
	private map: L.Map;
	private facitroutes: L.Layer[] = [];
	private haloLayers: L.Layer[] = [];
	private facitrouteLayer: L.LayerGroup;
	private expanded = false;

	private mapLoadedSubscription: Disposable;
	private mapMovedSubscription: Disposable;
	private facits: IFacitRouteContainer[];
	private mapClickedSubscription: Disposable;
	private crs: L.Proj.CRS;
	private	cluster: L.MarkerClusterGroup;

	constructor(
		private element: Element,
		private dataLayerService: DataLayerService,
		private eventAggregator: EventAggregator,
		private applicationRepo: ApplicationRepository) {
	}

	attached(): void {
		this.map = this.applicationRepo.map;
		this.crs = this.applicationRepo.mapCrs;

		if (this.map) {
			this.init();
		}
		else {
			this.mapLoadedSubscription = this.eventAggregator.subscribeOnce(MapEventType.MAP_LOADED, () => {
				this.map = this.applicationRepo.map;

				if (!this.map) {
					throw new Error("Could not get hold of map");
				}

				this.init();
			});
		}
	}
	detached(): void {
		if (this.mapLoadedSubscription) {
			this.mapLoadedSubscription.dispose();
			this.mapLoadedSubscription = undefined;
		}
		if (this.mapClickedSubscription) {
			this.mapClickedSubscription.dispose();
			this.mapClickedSubscription = undefined;
		}
		if (this.mapMovedSubscription) {
			this.mapMovedSubscription.dispose();
			this.mapMovedSubscription = undefined;
		}

		if (this.map && this.facitrouteLayer) {
			this.facitrouteLayer.removeFrom(this.map);
			this.facitrouteLayer = undefined;
		}

		this.facitroutes = [];
		this.facits = [];
	}
	protected toggle(): void {
		this.expanded = !this.expanded
	}

	private async init(): Promise<void> {
		if (this.facitrouteLayer) {
			return;
		}
		
		if (!this.mapClickedSubscription) {
			this.mapClickedSubscription = this.eventAggregator.subscribe(MapEventType.MAP_CLICKED, (latLng: L.LatLng) => {
				this.mapClicked(latLng);
			});
		}
		this.refreshFacitRoutes();
	}

	mapClicked(latLng: L.LatLng): void {
		if (!this.facitroutesChecked && (this.facits == undefined || this.facits.length < 1)) {
			return;
		}
		else{
			const pnt = this.crs.project(latLng);
			const ll = L.latLng(pnt.y, pnt.x);
			const buffer = this.getFeatureClickBufferMeters(ll)
			this.getFacitsAtLocation(pnt, buffer);
		}
	}
	
	getFacitsAtLocation(pnt: L.Point, buffer:number): void {
		const facitsContainers: IFacitRouteContainer[] = [];
		
		if(this.cluster != null){
			this.map.removeLayer(this.cluster);
			this.cluster = null;
		}
		this.haloLayers.forEach(layer => layer.removeFrom(this.map));
		
		this.facits.forEach(facit => {
			let find = false;
			const geom = this.getCoordinates(facit.facit.Geometry);
			geom.forEach(ll => {
				const distance = L.point(ll[0], ll[1]).distanceTo(pnt);
				if(distance <= buffer){
					find = true;
				}
			});

			if(find){
				facitsContainers.push(facit);
			}
		});
		if(facitsContainers.length > 1){
			this.createCluster(facitsContainers, pnt);
		}
		else if(facitsContainers.length == 1){
			const facit = facitsContainers[0];
			this.haloLayers.forEach(layer => {
				if(layer == facit.haloLayer){
					layer.addTo(this.map);
					layer.bindPopup(this.getFacitPopup(facit.facit));
				}
				else{
					this.map.removeLayer(layer);
				}
			});
		}
	}
	createCluster(facitsContainers: IFacitRouteContainer[], pnt: L.Point): void {
		this.cluster = L.markerClusterGroup(
			{ 
				spiderfyDistanceMultiplier: 3, 
				maxClusterRadius: 32, 
				showCoverageOnHover: false, 
				zoomToBoundsOnClick: false,
				iconCreateFunction: (cluster) => {
					const markers = cluster.getAllChildMarkers();
					const count = markers.length;
					const content = `<div><span>${count}</span></div>`;
					return L.divIcon( ({ html: content, className: "facit-cluster-large", iconSize: L.point(24, 22) } as any));
				}
			}
		);
		facitsContainers.forEach(container => {
			const facitIcon = L.divIcon({
				iconSize: [10, 10],
				className: "facit-cluster"
			});
			const latlng = this.crs.unproject(pnt);
			const facitMarker = L.marker(latlng, { icon: facitIcon });

			facitMarker.on("click", (e) => {
				this.haloLayers.forEach(layer => {
					if(layer == (container as IFacitRouteContainer).haloLayer){
						layer.addTo(this.map);
						layer.bindPopup(this.getFacitPopup(container.facit));
					}
					else{
						this.map.removeLayer(layer);
					}
				});
			});

			const label = container.id + '<br />' + container.facit.Site;
			facitMarker.bindTooltip(label, { permanent: true, direction: 'right', interactive: true }).closeTooltip();
			this.cluster.addLayer(facitMarker);
		});

		this.cluster.fireEvent('clusterclick', (a: any) => {
			a.layer.spiderfy();
			return;
		});
		this.cluster.addTo(this.map);
	}

	protected async layerCheckedChanged() {
		this.expanded = this.facitroutesChecked;

		await this.refreshFacitRoutes();

		const checkedChangedEvent = new CustomEvent('checked-change', {
			bubbles: true,
			detail: this.facitroutesChecked
		});

		this.element.dispatchEvent(checkedChangedEvent);

	}

	protected async refreshFacitRoutes() {
		if (!this.map) {
			return;
		}

		if (this.facitroutesChecked) {
			await this.getFacitRouteData();
		}

		if (this.facitroutesChecked && !this.facitroutesLayerAdded) {
			this.facitroutesLayerAdded = true;

			if (this.facitrouteLayer) {
				this.facitrouteLayer.removeFrom(this.map);
				this.facitrouteLayer = null;
				this.haloLayers.forEach(layer => layer.removeFrom(this.map));
				this.haloLayers = [];
			}

			this.facitrouteLayer = new L.LayerGroup(this.facitroutes);
			this.facitrouteLayer.addTo(this.map);
		}
		else if (this.facitroutesLayerAdded && !this.facitroutesChecked) {
			this.facitroutesLayerAdded = false;

			if (this.facitrouteLayer) {
				this.facitrouteLayer.removeFrom(this.map);
				this.facitrouteLayer = null;
				this.haloLayers.forEach(layer => layer.removeFrom(this.map));
				this.haloLayers = [];
			}
		}
	}

	private async getFacitRouteData(): Promise<void> {
		try {
			this.facits = await this.dataLayerService.getFacitRoutes();
			const layers: L.Layer[] = [];


			this.facits.forEach(facit => {
				const geom = this.getCoordinates(facit.facit.Geometry);

				const geojson: Proj4GeoJSONFeature = {
					type: 'Feature',
					geometry: {
						type: 'LineString',
						coordinates: geom
					},
					crs: {
						type: 'name',
						properties: {
							name: 'urn:ogc:def:crs:EPSG::3006'
						}
					},
					properties: {
						facit: facit
					}
				};

				const style: L.PathOptions = {
					color: '#ff00ff',
					weight: 3
				};

				const haloLayer = L.Proj.geoJson(geojson, {
					style: () => {
						return {
							color: '#ffff00',
							opacity: 0.8,
							weight: 10
						};
					}
				});

				this.haloLayers.push(haloLayer); 

				const layer = L.Proj.geoJson(geojson, {
					style: () => {
						return style;
					}
				});

				layers.push(layer);
				facit.layer = layer;
				facit.haloLayer = haloLayer;
			});

			this.facitroutes = layers;

		}
		catch (error) {
			console.log(error);
		}
	}

	getCoordinates(wkt: string): Position[] {
		const parse = wktToGeoJSON(wkt);
		const latlngs = ((parse as GeoJSON.LineString).coordinates);
		const geo = latlngs[0].map(x => { return [x[0], x[1]]; });
		return geo;
	}

	getFacitPopup(facit: IFacitRoute): HTMLElement {
		const contatiner = L.DomUtil.create('div', 'search-popup leaflet-contextmenu');
		contatiner.style.display = 'block';

		const innerContainer = L.DomUtil.create('div', 'facit-popup-container', contatiner);
		innerContainer.style.padding = '0 12px';

		const facitId = L.DomUtil.create('h5', '', innerContainer);
		facitId.innerText = `Facitrutt: ${facit.FacitId}`;
		facitId.style.fontWeight = 'bold';

		const E = L.DomUtil.create('h5', '', innerContainer);
		E.innerText = `E: ${facit.E}`;

		const N = L.DomUtil.create('h5', '', innerContainer);
		N.innerText = `N: ${facit.N}`;

		const site = L.DomUtil.create('h5', '', innerContainer);
		site.innerText = `Mottagningsplats: ${facit.Site}`;

		return contatiner;
	}
	private getFeatureClickBufferMeters(latLng: L.LatLng, bufferPixels = 10): number {

		const pointC = this.map.latLngToContainerPoint(latLng);
		const pointX = [pointC.x + bufferPixels, pointC.y];
		const pointY = [pointC.x, pointC.y + bufferPixels]; 
		
		const latLngC = this.map.containerPointToLatLng(pointC);
		const latLngX = this.map.containerPointToLatLng(pointX as any);
		const latLngY = this.map.containerPointToLatLng(pointY as any);
		
		const distanceX = latLngC.distanceTo(latLngX);
		const distanceY = latLngC.distanceTo(latLngY);
		const buffer = Math.max(distanceX, distanceY);

		return buffer;
	}
}


