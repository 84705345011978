import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { EnvironmentConfiguration } from '../../../../../../services/configuration/services/configuration';
import { IStorageSite } from '../models/storage-site.interface';
import { StorageSite } from '../models/storage-site.model';

@autoinject()
export class StorageSiteService {
	private apiBaseUrl: string;

	constructor(
		private httpClient: HttpClient,
		private config: EnvironmentConfiguration) {
		this.apiBaseUrl = this.config.env.ApiBaseUrl;
	}

	async getStorageSites(siteId:string):Promise<IStorageSite[]>{
		const url = `${this.apiBaseUrl}/Sites/GetStorageSite?siteId=${siteId}`
		const storage = [];
		await this.httpClient.fetch(url)
			.then(response => response.json())
			.then((data: StorageSite[]) => {
				data.forEach(e => {
					const siteStorage: IStorageSite = {
						Id: e.Id,
						SiteId: e.SiteId,
						Name: e.Name,
						X: e.X,
						Y: e.Y,
						Distance: e.Distance
					}
					storage.push(siteStorage);
				});	
			});
			console.log("storage: ", storage);
		return storage;
	}
}


