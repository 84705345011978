import { autoinject, bindable, bindingMode, Disposable} from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import * as L from 'leaflet';
import { GeoJsonTypes, LineString, Position } from 'geojson';
import { wktToGeoJSON } from "@terraformer/wkt"
import { DataLayerService } from 'services/data-layer/data-layer-service';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { Proj4GeoJSONFeature } from 'proj4leaflet';
import { MapEventType } from 'vv-constants/map-event-type.enum';
import { IDynamicData } from 'services/data-layer/models/dynamic-data.interface';

@autoinject
export class DynamicData {
	protected dynamicDataChecked = false;
	private expanded = false;
	private mapLoadedSubscription: Disposable;
	private mapClickedSubscription: Disposable;
	private map: L.Map;
	private crs: L.Proj.CRS;
	private mapCrs;
	private dynamicsLayer: L.LayerGroup;
	private dynamicLayers: L.Layer[] = [];
	private dynamicsData: IDynamicData[];
	private dynamicLinestring: L.Layer;
	dynamicsLayerAdded: any;
	dynamicLoading:boolean;
	icons: IIconContainer[] = []; 

	constructor(
		private element: Element,
		private dataLayerService: DataLayerService,
		private eventAggregator: EventAggregator,
		private applicationRepo: ApplicationRepository) {
	}

	attached(): void {
		this.map = this.applicationRepo.map;
		this.crs = this.applicationRepo.mapCrs;
		this.mapCrs = this.applicationRepo.mapCrs;
		if (this.map) {
			this.init();
		}
		else {
			this.mapLoadedSubscription = this.eventAggregator.subscribeOnce(MapEventType.MAP_LOADED, () => {
				this.map = this.applicationRepo.map;

				if (!this.map) {
					throw new Error("Could not get hold of map");
				}

				this.init();
			});
		}
	}
	detached(): void {
		if (this.mapLoadedSubscription) {
			this.mapLoadedSubscription.dispose();
			this.mapLoadedSubscription = undefined;
		}
		if (this.mapClickedSubscription) {
			this.mapClickedSubscription.dispose();
			this.mapClickedSubscription = undefined;
		}
		if (this.map && this.dynamicsLayer) {
			this.dynamicsLayer.removeFrom(this.map);
			this.dynamicsLayer = undefined;
		}
		if(this.dynamicLinestring){
			this.dynamicLinestring.removeFrom(this.map);
			this.dynamicLinestring = null;
		}
		this.dynamicsData = [];
		this.icons = [];
	}

	protected toggle() {
		this.expanded = !this.expanded
	}

	private async init(): Promise<void> {
		if (this.dynamicsLayer) {
			return;
		}

		this.refreshDynamics();
	}

	protected async layerCheckedChanged() {
		this.expanded = this.dynamicDataChecked;

		await this.refreshDynamics();

		const checkedChangedEvent = new CustomEvent('checked-change', {
			bubbles: true,
			detail: this.dynamicDataChecked
		});

		this.element.dispatchEvent(checkedChangedEvent);
	}

	protected async refreshDynamics() {
		if (!this.map) {
			return;
		}

		if (this.dynamicDataChecked) {
			this.dynamicLoading = true;
			await this.getDynamicData();
		}
		if (this.dynamicDataChecked && !this.dynamicsLayerAdded) {
			this.dynamicsLayerAdded = true;

			if (this.dynamicsLayer) {
				this.dynamicsLayer.removeFrom(this.map);
				this.dynamicsLayer = null;
			}

			this.dynamicsLayer = new L.LayerGroup(this.dynamicLayers);
			try {
				this.dynamicsLayer.addTo(this.map);
			} catch (error) {
				console.log(error);
			}

		}
		else if (this.dynamicsLayerAdded && !this.dynamicDataChecked) {
			this.dynamicsLayerAdded = false;

			if (this.dynamicsLayer) {
				this.dynamicsLayer.removeFrom(this.map);
				this.dynamicsLayer = null;
			}
			if(this.dynamicLinestring){
				this.dynamicLinestring.removeFrom(this.map);
				this.dynamicLinestring =  null;
			}
			if(this.icons){
				this.icons = [];
			}
		}
	}

	private async getDynamicData() {
		try {
			this.dynamicsData = await this.dataLayerService.getDynamicData();
			this.dynamicLoading = false;
			const layers: L.Layer[] = [];

			this.dynamicsData.forEach(dynamic => {
				const geom = this.getGeometryObject(dynamic.shapeField);
				const coordinates = (geom as any).coordinates;
				let latlngs1: Position;
				let isLineString = false;
				if (geom.type.toString() == "LineString") {
					const coordinateLinestring = this.getCoordinatesLineString(coordinates);
					const center = coordinateLinestring[Math.floor(coordinateLinestring.length / 2)];
					const pos: Position = center;
					latlngs1 = pos;
					isLineString = true;
				}
				else {
					const latlng = this.mapCrs.project(new L.LatLng(coordinates[1], coordinates[0]));
					const pos: Position = [latlng];
					latlngs1 = pos;
				}
				
				const geojson: Proj4GeoJSONFeature = {
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: latlngs1
					},
					properties: {

					},
					crs: {
						type: 'name',
						properties: {
							name: 'urn:ogc:def:crs:EPSG::3006'
						}
					}
				};

				const icon = dynamic.iconIdField ? dynamic.iconIdField : 'trafficMessage';
				const myIcon = L.icon({
					iconUrl: 'https://api.trafikinfo.trafikverket.se/v2/icons/' + icon + '?type=png16x16',
					iconAnchor: [13, 38]
				});

				const layer = L.Proj.geoJson(geojson, {
					onEachFeature: (feature, layer) => {
						layer.bindPopup(this.getDataPopup(dynamic, isLineString));
					},
					pointToLayer: function (feature, latlng) {
						return L.marker(latlng, { icon: myIcon });
					}
				});
				layers.push(layer);
				if(!(this.icons.some(i => i.id === dynamic.iconIdField))){
					this.icons.push({
						id: dynamic.iconIdField, 
						url: "https://api.trafikinfo.trafikverket.se/v2/icons/" + dynamic.iconIdField +"?type=png16x16",
						message: dynamic.messageCodeField
					});
				}
			});
			this.dynamicLayers = layers;
			console.log(this.icons);
		}
		catch (error) {
			console.log(error);
		}
	}
	private getCoordinatesLineString(coordinates: any,) {
		const coordinateLinestring: Position[] = [];
		coordinates.forEach(ll => {
			const latlng = this.mapCrs.project(new L.LatLng(ll[1], ll[0]));
			const pos: Position = [latlng];
			coordinateLinestring.push(pos);
		});
		return coordinateLinestring;
	}

	getDataPopup(dynamic: IDynamicData, isLineString: boolean): HTMLElement {
		const contatiner = L.DomUtil.create('div', 'search-popup leaflet-contextmenu');
		contatiner.style.display = 'block';

		const innerContainer = L.DomUtil.create('div','dynamic-data-popup', contatiner);
		innerContainer.style.padding = '20px 12px 20px';

		const img = L.DomUtil.create('img', '', innerContainer);
		img.setAttribute('src', 'https://api.trafikinfo.trafikverket.se/v2/icons/' + dynamic.iconIdField + '?type=png16x16');
		img.style.paddingRight = '9px';

		const messageCode = L.DomUtil.create('h5', '', innerContainer);
		messageCode.innerText = `${dynamic.messageCodeField}`;
		messageCode.style.fontWeight = 'bold';
		messageCode.style.display = 'contents';

		const start = L.DomUtil.create('h5', '', innerContainer);
		start.innerText = `Start: ${dynamic.startTimeField}`;
		start.style.paddingTop = '9px';

		const end = L.DomUtil.create('h5', '', innerContainer);
		end.innerText = `Slut: ${dynamic.endTimeField}`;

		const limit = L.DomUtil.create('h5', '', innerContainer);
		limit.innerText = `Begränsning: ${dynamic.temporaryLimitField}`;
		limit.style.paddingBottom = '11px';

		if (isLineString) {
			const utbredningButton = L.DomUtil.create('button', 'btn vv-btn', innerContainer) as HTMLButtonElement;
			utbredningButton.style.height = "2em";
			utbredningButton.style.lineHeight = "1em";
			utbredningButton.style.backgroundColor = "#E23F37";
			utbredningButton.style.color = "#fff"
			utbredningButton.textContent = "Visa utbredning";
			utbredningButton.style.paddingBottom = "5px";
			utbredningButton.onclick = () => {
				this.showLineString(dynamic.shapeField);
			};
		}
		return contatiner;

	}

	showLineString(shapeField: string) {
		if (this.dynamicLinestring){
			this.dynamicLinestring.removeFrom(this.map);
		}
		const geom = this.getGeometryObject(shapeField);
		const coordinates = (geom as any).coordinates;
		const coordinateLinestring = this.getCoordinatesLineString(coordinates);

		const geojson: Proj4GeoJSONFeature = {
			type: 'Feature',
			geometry: {
				type: 'LineString',
				coordinates: coordinateLinestring
			},
			crs: {
				type: 'name',
				properties: {
					name: 'urn:ogc:def:crs:EPSG::3006'
				}
			},
			properties: {
				linestring: coordinateLinestring
			}
		};
		const style: L.PathOptions = {
			color: '#e23f37',
			weight: 5
		};
		const layer = L.Proj.geoJson(geojson, {
			style: (feature: any) => {
				return style;
			}
		});
		this.dynamicLinestring = layer;
		this.dynamicLinestring.addTo(this.map);
	}

	getGeometryObject(wkt: string): GeoJSON.GeometryObject {
		const parse = wktToGeoJSON(wkt);
		return parse;
	}
}
export interface IIconContainer{
	id: string;
	url:string;
	message:string;
}