// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tp-led .toggle-panel {
    margin-left: -5px;
}

.tp-led .toggle-panel-content.expanded {
    background-color: #E0E0E0;
}

.tp-led .toggle-panel-content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.tp-led .toggle-panel-content li {
    position: relative;
}

.tp-led .td-checkbox{
	padding: 5px 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/layer-picker/layer-groups/tp-leder/tp-led/tp-led.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;IACrB,UAAU;IACV,SAAS;AACb;;AAEA;IACI,kBAAkB;AACtB;;AAEA;CACC,iBAAiB;AAClB","sourcesContent":[".tp-led .toggle-panel {\r\n    margin-left: -5px;\r\n}\r\n\r\n.tp-led .toggle-panel-content.expanded {\r\n    background-color: #E0E0E0;\r\n}\r\n\r\n.tp-led .toggle-panel-content ul {\r\n    list-style-type: none;\r\n    padding: 0;\r\n    margin: 0;\r\n}\r\n\r\n.tp-led .toggle-panel-content li {\r\n    position: relative;\r\n}\r\n\r\n.tp-led .td-checkbox{\r\n\tpadding: 5px 10px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
