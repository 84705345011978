// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
    width: 22px;
    height: 22px;
    -webkit-animation: rotation 1.5s infinite linear;
    -moz-animation: rotation 1.5s infinite linear;
    animation: rotation 1.5s infinite linear;
  }
  .spinner-medium {
    width: 38px;
    height: 38px;
    -webkit-animation: rotation 1.5s infinite linear;
    -moz-animation: rotation 1.5s infinite linear;
    animation: rotation 1.5s infinite linear;
  }

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/common/loader/loader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,gDAAgD;IAChD,6CAA6C;IAC7C,wCAAwC;EAC1C;EACA;IACE,WAAW;IACX,YAAY;IACZ,gDAAgD;IAChD,6CAA6C;IAC7C,wCAAwC;EAC1C;;AAEF;IACI;QACI,+BAA+B;IACnC;IACA;QACI,iCAAiC;IACrC;AACJ","sourcesContent":[".spinner {\r\n    width: 22px;\r\n    height: 22px;\r\n    -webkit-animation: rotation 1.5s infinite linear;\r\n    -moz-animation: rotation 1.5s infinite linear;\r\n    animation: rotation 1.5s infinite linear;\r\n  }\r\n  .spinner-medium {\r\n    width: 38px;\r\n    height: 38px;\r\n    -webkit-animation: rotation 1.5s infinite linear;\r\n    -moz-animation: rotation 1.5s infinite linear;\r\n    animation: rotation 1.5s infinite linear;\r\n  }\r\n\r\n@keyframes rotation {\r\n    from {\r\n        -webkit-transform: rotate(0deg);\r\n    }\r\n    to {\r\n        -webkit-transform: rotate(359deg);\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
