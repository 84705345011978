import { autoinject } from 'aurelia-framework';
import * as L from 'leaflet';
import * as $ from 'jquery';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { SearchType } from 'services/place-search/models/search-type.enum';
import interact from 'interactjs';
import { MapConfiguration } from 'services/map-configuration/map-configuration';

@autoinject()
export class InteractDraggableCustomAttribute  {

	private map: L.Map;
	private crs: L.Proj.CRS
	private startPos: { x: number; y: number; dX: number; dY: number; };
	private draggable: interact.Interactable;
	private mapPane: HTMLElement;
	private clone: any; // JQuery Element

	constructor(
		private element: Element,
		private mapConfiguration: MapConfiguration,
		private applicationRepo: ApplicationRepository) {

	}

	attached(): void {
		this.createDraggable();
	}

	bind(): void {
		this.map = this.applicationRepo.map;
		this.crs = this.mapConfiguration.getMapCrs().crs;

		this.mapPane = (document.getElementsByClassName('leaflet-map-pane')[0] as HTMLElement);
	}

	unbind(): void {
		if (this.draggable) {
			(this.draggable as any).unset();
		}
	}

	private createDraggable(): void {
		const options = {
			inertia: false,
		};

		this.draggable = interact(this.element)
			.draggable(options)
			.on('dragstart', (event) => this.dragStart(event))
			.on('dragmove', (event) => this.dragMove(event))
			.on('dragend', (event) => this.dragEnd(event));
	}

	private dragStart(event: interact.InteractEvent): void {
		const rect = (interact as any).getElementRect($(event.target).find('span')[1]);
		const spanX = rect.left + rect.width  / 2;
		const spanY = rect.bottom + rect.height / 2;

		this.startPos = {
			x: spanX,
			y: spanY,
			dX: spanX - event.clientX,
			dY: rect.bottom - event.clientY
		}

		// Create a clone and append to DOM
		this.clone = $(event.target).clone();
		this.clone.appendTo('body');
		this.clone.css('position','absolute').offset($(event.target).offset())

		this.dispatch('interact-dragstart', event);
	}

	private dragMove(event: interact.InteractEvent): void {
		const target = event.target;
		
		// Keep the dragged position in the data-x/data-y attributes
		const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
		const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

		// Translate the element
		target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

		// Update the posiion attributes
		target.setAttribute('data-x', x);
		target.setAttribute('data-y', y);

		const cloneTarget = this.clone[0];
		cloneTarget.setAttribute('data-x', x);
		cloneTarget.setAttribute('data-y', y);

		cloneTarget.style.webkitTransform = target.style.webkitTransform;

		this.dispatch('interact-dragmove', event);
	}

	private dragEnd(event: interact.InteractEvent): void {

		// Restore marker to original position
		const target = event.target;

		target.style.webkitTransform = target.style.transform = null;
		target.removeAttribute('data-x');
		target.removeAttribute('data-y');

		// Remove the clone from DOM
		this.clone.remove();

		const mapTransform = this.getTransformOffset();

		const point: any = { 
			x: event.clientX + this.startPos.dX - mapTransform[0], 
			y: event.clientY + this.startPos.dY - mapTransform[1]
		};

		const latlng = this.map.layerPointToLatLng(point);
		console.log(latlng);
		const projected = this.crs.project(latlng as any);
		console.log(projected);
		const x = Math.round(projected.x);
		const y = Math.round(projected.y);
		const location = {
			id: '',
			name: Math.round(projected.x) + ', ' + Math.round(projected.y),
			type: SearchType.COORDINATE,
			latlng: latlng,
			projected: projected,
			easting: x,
			northing: y
		};
		console.log(location);
		this.dispatch('interact-dragend', location)
	}

	private getTransformOffset(): number[] {
		const mapTransform = this.mapPane.style.transform.split(',');
		const dX = +(mapTransform[0].split('(')[1].replace('px', ''));
		const dY = +(mapTransform[1].replace('px', ''));

		return [dX, dY];
	}

	private dispatch(name, data): void {
		this.element.dispatchEvent(
			new CustomEvent(name, {
				bubbles: true,
				detail: data
			})
		);
	}
}
