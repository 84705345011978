import { autoinject, Disposable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import * as L from 'leaflet';
import { GeoJsonTypes } from 'geojson';
import { DataLayerService } from 'services/data-layer/data-layer-service';
import { BlockagePopup } from 'components/common/popups/blockage-popup';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { Proj4GeoJSONFeature } from 'proj4leaflet';
import { MapEventType } from 'vv-constants/map-event-type.enum';
import { ICoordinateBlock } from 'services/data-layer/models/blocks/coordinate-block.interface';


@autoinject
export class Blockages {
    protected blockagesChecked = false;
    
    private map: L.Map;
    private blockages: L.Layer[] = [];
    private blockageLayer: L.LayerGroup;
    private blockagesLayerAdded: boolean;

	private expanded = false;

    private mapLoadedSubscription: Disposable;
    private mapMovedSubscription: Disposable;

    constructor(
		private element: Element, 
        private dataLayerService: DataLayerService, 
        private eventAggregator: EventAggregator,
        private blockagePopup: BlockagePopup,
        private applicationRepo: ApplicationRepository) {

    }

    attached(): void {
        this.mapMovedSubscription = this.eventAggregator.subscribe(MapEventType.MAP_MOVED, () => {
			this.refreshBlockages();
		});
		

		this.map = this.applicationRepo.map;
		if (this.map) {
			this.init();
		}
		else {
			this.mapLoadedSubscription = this.eventAggregator.subscribeOnce(MapEventType.MAP_LOADED, () => {
				this.map = this.applicationRepo.map;

				if (!this.map) {
					throw new Error("Could not get hold of map");
				}

				this.init();
			});
		}
    }

    detached(): void {
        if (this.mapLoadedSubscription) {
			this.mapLoadedSubscription.dispose();
			this.mapLoadedSubscription = undefined;
		}

		if (this.mapMovedSubscription) {
			this.mapMovedSubscription.dispose();
			this.mapMovedSubscription = undefined;
		}

		if (this.map && this.blockageLayer) {
			this.blockageLayer.removeFrom(this.map);
			this.blockageLayer = undefined;
		}

		this.blockages = [];
    }
	
	protected async layerCheckedChanged() {
		this.expanded = this.blockagesChecked;

		await this.refreshBlockages();

		const checkedChangedEvent = new CustomEvent('checked-change', {
			bubbles: true,
			detail: this.blockagesChecked
		});

		this.element.dispatchEvent(checkedChangedEvent);
	}

    protected async refreshBlockages() {
		if (!this.map) {
			return;
		}

		if (this.blockagesChecked) {
			await this.getBlockageData();
		}
		
		if (this.blockagesChecked && !this.blockagesLayerAdded) {
			this.blockagesLayerAdded = true;

			if (this.blockageLayer) {
				this.blockageLayer.removeFrom(this.map);
				this.blockageLayer = null;
			}

			this.blockageLayer = new L.LayerGroup(this.blockages);
			this.blockageLayer.addTo(this.map);
		}
		else if (this.blockagesLayerAdded && !this.blockagesChecked) {
			this.blockagesLayerAdded = false;

			if (this.blockageLayer) {
				this.blockageLayer.removeFrom(this.map);
				this.blockageLayer = null;
			}
		}
    }
	
	protected toggle() {
		this.expanded = !this.expanded
	}

	private async init(): Promise<void> {
		if (this.blockageLayer) {
			return;
		}

		this.refreshBlockages();
	}

    private async getBlockageData(): Promise<void> {
        try {
            const features = await this.dataLayerService.getDynamicBlocks();
            const layers: L.Layer[] = [];
    
            const featureLineStyle = {
                color: '#ff00ff',
                opacity: 0.8,
                weight: 6
            };
    
            const featureAreaStyle = {
                color: '#ff00ff',
                opacity: 0.8,
                weight: 2
            };
    
            features.forEach(feature => {
                let featureCoordinates;
    
                if (feature.GeometryTypeString === "Point") {
                    featureCoordinates = [feature.Geometry[0][0].X, feature.Geometry[0][0].Y];
                }
                else {
                    featureCoordinates = feature.Geometry.map(x => {
                        return x.map(y => {
                            { return [y.X, y.Y]; }
                        })
                    });
                }
    
                const geojson: Proj4GeoJSONFeature = {
                    type: 'Feature',
                    geometry: {
                        type: feature.GeometryTypeString,
                        coordinates: featureCoordinates
                    },
                    crs: {
                        type: 'name',
                        properties: {
                            name: 'urn:ogc:def:crs:EPSG::3006'
                        }
                    },
                    properties: {
                        blockage: feature
                    }
                };
    
                const layer = L.Proj.geoJson(geojson, {
                    onEachFeature: (feature, layer) => {
                        layer.bindPopup(this.blockagePopup.getBlockagePopup(feature.properties.blockage));
                    },
                    pointToLayer: (feature, latlng) => {
                        const blockage = feature.properties.blockage as ICoordinateBlock;
                        const pointStyle = featureAreaStyle as any;
                        pointStyle.radius = blockage.Radius;
    
                        return L.circle(latlng, pointStyle);
                    },
                    style: (feature: any) => {
                        const blockage = feature.properties.blockage as ICoordinateBlock;
                        if (blockage.BlockageTypeString === "Nvdb" || blockage.BlockageTypeString === "Link") {
                            return featureLineStyle;
                        }
    
                        return featureAreaStyle;
                    }
                });
    
                layers.push(layer);
            })
    
            this.blockages = layers;
        }
        catch (error) {
            // 
        }
	}
}