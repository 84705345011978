import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import * as L from 'leaflet';
import { TpLeder } from '../tp-leder';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { Proj4GeoJSONFeature } from 'proj4leaflet';
import { ITpLedContainer } from '../models/tp-led-container.interface';
import { IFeature } from 'services/data-layer/models/features/feature.interface';


@autoinject()
export class TpLed {

    @bindable
    parent: TpLeder;

    @bindable item: ITpLedContainer;
    protected title: string;

    protected checked = false;
	protected indeterminate = false;

    private map: L.Map;
    private layers: { [key: string] : L.Proj.GeoJSON; } = {}; // Dictionary of tpled-id and corresponding layer

    constructor(private applicationRepo: ApplicationRepository) {
        this.map = this.applicationRepo.map;
    }

    bind(): void {
        this.title = `${this.item.site.name} (${this.item.site.id})`;
        this.addAllLayers();
        this.checked = true;
    }

    unbind(): void {
        this.removeAllLayers();
        this.checked = false;
        this.indeterminate = false;
    }

    @computedFrom('item.features')
    protected get hasFeatures(): boolean {
        return this.item.features && this.item.features.length > 0;
    }

    protected close() {
        this.removeAllLayers();
        this.checked = false;
        this.indeterminate = false;
        this.parent.deleteItem(this.item);
    }

    protected layerGroupCheckedChanged($event: any): void {
        if (this.checked) {
            this.removeAllLayers();
            this.addAllLayers();
        }
        else {
            this.removeAllLayers();
        }

        this.indeterminate = false;
    }

    protected layerCheckedChanged(evt: any, id: string): void {
        if (this.layers[id]) {
            this.removeLayer(id);
        }
        else {
            this.addLayer(this.findFeature(id));
        }

        const checkedCount = this.item.features.filter(x => (x as any).checked).length;
        this.checked = checkedCount > 0;
        this.indeterminate = checkedCount > 0 && checkedCount < this.item.features.length;
    }
	
	private findFeature(id: string): IFeature {
        return this.item.features.find(x => x.AttributeValues[0][0].TranslatedValue === id);
    }

    private addAllLayers(): void {
        this.item.features.forEach((x) => {
            this.addLayer(x);
            (x as any).checked = true;
        });
    }

    private addLayer(feature: IFeature): void {
        const featureCoordinates = feature.Geometry.map(x => {
            return x.map(y => {
                { return [y.X, y.Y]; }
            })
        });

        const geojson: Proj4GeoJSONFeature = {
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: featureCoordinates
            },
            properties: {

            },
            crs: {
                type: 'name',
                properties: {
                    name: 'urn:ogc:def:crs:EPSG::3006'
                }
            }
        };

        const layer = L.Proj.geoJson(geojson, {
            style: (feature: any) => {
                return {
					color: "#FF0000",
                    opacity: 1,
                    weight: 5
                };
            }
        });

        this.layers[feature.AttributeValues[0][0].TranslatedValue] = layer;
        this.layers[feature.AttributeValues[0][0].TranslatedValue].addTo(this.map);
    }

    private removeAllLayers(): void {
        if (this.layers) {
            for (const key in this.layers) {
                this.removeLayer(key);
                const feature = this.findFeature(key);
                (feature as any).checked = false;
            }

            this.layers = {};
        }
    }

    private removeLayer(id: string): void {
        this.layers[id].removeFrom(this.map);
        delete this.layers[id];
    }
}