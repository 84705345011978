import { autoinject, computedFrom, Disposable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DataLayerService } from 'services/data-layer/data-layer-service';
import { IPlaceSearchItem } from 'services/place-search/models/place-search-item.interface';
import { ITpLedContainer } from './models/tp-led-container.interface';
import { FeatureEventType } from 'vv-constants/feature-event-type.enum';

@autoinject()
export class TpLeder {

	protected thisVM = this;
    protected loading = false;
    protected expanded = false;
    protected items: ITpLedContainer[] = [];

    private subscription: Disposable;

    @computedFrom('items.length')
    protected get hasTpLeder(): boolean {
        return this.items && this.items.length > 0;
    }

    constructor(
        private eventAggregator: EventAggregator,
        private dataLayerService: DataLayerService) {
        
    }

    attached(): void {
        this.subscription = this.eventAggregator.subscribe(FeatureEventType.SHOW_TP_LEDER_FOR, (site: IPlaceSearchItem) => {
			this.getFeatures(site);
		});
    }

    detached(): void {
        this.subscription.dispose();
    }

    close(): void {
        this.items = [];
    }

    deleteItem(item: ITpLedContainer): void {
        const index = this.items.findIndex(x => x.id == item.id);
        this.items.splice(index, 1);
    }

    private async getFeatures(site: IPlaceSearchItem): Promise<void> {

        try {
            const alreadyAdded = this.items.some(x => x.id == site.id);
            if (alreadyAdded){
                return;
            }
    
            this.expanded = true;
            this.loading = true;
            const features = await this.dataLayerService.getTpFeatures(site.id);

            this.items.push({
                id: site.id,
                site: site,
                features: features,
                expanded: true
			});
    
            this.loading = false;
        }
        catch (error) {
            this.loading = false;
        }
    }
}

