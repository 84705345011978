// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaflet-contextmenu div.dynamic-data-popup > h5 {
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 12px;
}
.dynamics-loader-container{
	padding-top: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/layer-picker/dynamic-data/dynamic-data.css"],"names":[],"mappings":"AAAA;IACI,2DAA2D;IAC3D,eAAe;AACnB;AACA;CACC,gBAAgB;AACjB","sourcesContent":[".leaflet-contextmenu div.dynamic-data-popup > h5 {\r\n    font-family: \"Helvetica Neue\", Arial, Helvetica, sans-serif;\r\n    font-size: 12px;\r\n}\r\n.dynamics-loader-container{\r\n\tpadding-top: 2px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
