import { autoinject, bindable, bindingMode, Disposable, computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import * as L from 'leaflet';
import { FeatureTypeId, SdcDkNsId, RfdDkNsId } from 'components/admin/admin-constants/index'
import { DataLayerService } from 'services/data-layer/data-layer-service';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { AdminRouteService } from 'components/admin/services/admin-route/admin-route-service';
import { Proj4GeoJSONFeature } from 'proj4leaflet';
import { MapEventType } from 'vv-constants/map-event-type.enum';
import { LayerEventType } from 'vv-constants/layer-event-type.enum';
import { FeatureEventType } from 'vv-constants/feature-event-type.enum';
import { IPhenomena } from 'services/data-layer/models/phenomena.interface';
import { IFeature } from 'services/data-layer/models/features/feature.interface';
import { IAttributeTableEventData } from './attribute-table/models/attribute-table-event-data.interface';
import { AttributeTableEventType } from './attribute-table/models/attribute-table-event-type.enum';
import { IFeatureGroup } from './models/feature-group.interface';
import { IRoute } from 'components/admin/services/admin-route/models/route-interface';
import { AdminRouteEventItemType } from 'components/admin/admin-constants/event-types/admin-route-event-type.enum';

@autoinject()
export class FeatureInfo {
	@bindable({ defaultBindingMode: bindingMode.twoWay }) badgeValue: string;
	@bindable({ defaultBindingMode: bindingMode.twoWay }) anyFeatures;
	@bindable({ defaultBindingMode: bindingMode.twoWay }) showLoading = true;
	@bindable({ defaultBindingMode: bindingMode.twoWay }) showFeatureBtn = true;

	featureGroups: IFeatureGroup[];
	isMultiSelect = false;
	loading: boolean;

	private map: L.Map;
	private selectedFeatureLayer: L.Layer;
	private phenomenas: IPhenomena[];
	
	private mapLoadedSubscription: Disposable;
	private featureIdentifiedStartedSubscription: Disposable;
	private featureIdentifiedSubscription: Disposable;
	private layersRemovedSubscription: Disposable;
	private featuresSelectedSubscription: Disposable;
	private layerViewDateChangedSubscription: Disposable;

	private selectedFeatures:  { [key: string]: IAttributeTableEventData; } = {};
	private _canSelectRoute: boolean;

	@computedFrom('_canSelectRoute')
	get canSelectRoute(): boolean {
		return this._canSelectRoute;
	}

	constructor(
		private dataLayerService: DataLayerService, 
		private eventAggregator: EventAggregator, 
		private applicationRepo: ApplicationRepository,
		private adminRouteService: AdminRouteService) {

		this.dataLayerService.getPhenomenon().then(x => {
			this.phenomenas = x;
		});
	}
	
	attached(): void {
		this.featureIdentifiedStartedSubscription = this.eventAggregator.subscribe(FeatureEventType.FEATURE_IDENTIFIED_STARTED, () => {
			this.loading = true;
			this.selectedFeatures = {};
			this._canSelectRoute = false;
			this.setAnyFeatures();
		});

		this.featureIdentifiedSubscription = this.eventAggregator.subscribe(FeatureEventType.FEATURE_IDENTIFIED, (data) => {
			this.loading = false;

			const features = data.features as IFeature[];
			this.parseFeatures(features);
		});

		this.layersRemovedSubscription = this.eventAggregator.subscribe(LayerEventType.LAYERS_REMOVED, (layers) => {
			this.layersUpdated(layers);
			
			layers.forEach(x => {
				delete this.selectedFeatures[x];
			});

		});
		
		this.featuresSelectedSubscription = this.eventAggregator.subscribe(AttributeTableEventType.FEATURES_SELECTED, (data: IAttributeTableEventData) => {
			if (data.linkSequences && data.linkSequences.length > 0) {
				this.selectedFeatures[data.layerName] = data;
			}
			else {
				delete this.selectedFeatures[data.layerName];
			}

			const selectedRoteFeature = this.getSelectedRouteFeature();
			
			if (!selectedRoteFeature) {
				this._canSelectRoute = false;
			}
			else {
				this._canSelectRoute = true;
			}
		});
		
		this.layerViewDateChangedSubscription = this.eventAggregator.subscribe(LayerEventType.VIEW_DATE_CHANGED, () => {
			this.parseFeatures(null);
		});

		this.map = this.applicationRepo.map;
		if (!this.map) {
			this.mapLoadedSubscription = this.eventAggregator.subscribeOnce(MapEventType.MAP_LOADED, () => {
				this.map = this.applicationRepo.map;

				if (!this.map) {
					throw new Error("Could not get hold of map");
				}
			});
		}

		this.setAnyFeatures();
	}

	detached(): void {
		if (this.mapLoadedSubscription) {
			this.mapLoadedSubscription.dispose();
			this.mapLoadedSubscription = undefined;
		}

		if (this.featureIdentifiedStartedSubscription) {
			this.featureIdentifiedStartedSubscription.dispose();
			this.featureIdentifiedStartedSubscription = undefined;
		}

		if (this.featureIdentifiedSubscription) {
			this.featureIdentifiedSubscription.dispose();
			this.featureIdentifiedSubscription = undefined;
		}

		if (this.layersRemovedSubscription) {
			this.layersRemovedSubscription.dispose();
			this.layersRemovedSubscription = undefined;
		}

		if (this.featuresSelectedSubscription) {
			this.featuresSelectedSubscription.dispose();
			this.featuresSelectedSubscription = undefined;
		}

		if (this.layerViewDateChangedSubscription) {
			this.layerViewDateChangedSubscription.dispose();
			this.layerViewDateChangedSubscription = undefined;
		}

		this.featureGroups = [];
		if (this.selectedFeatureLayer) {
			this.selectedFeatureLayer.removeFrom(this.map);
			this.selectedFeatureLayer = undefined;		
		}
	}
	
	/**
	 * Gets called from attribute-panel
	 */
	reset(): void {
		this.parseFeatures(null);
	}

	async openSelectedRoute(): Promise<void> {
		const selectedRoteFeature = this.getSelectedRouteFeature();
		if (selectedRoteFeature.linkSequences.length > 1) {
			// Should only be one oid here
			return;
		}

		const linkSequence = selectedRoteFeature.linkSequences[0];

		let route: IRoute;
		if (selectedRoteFeature.nsId === SdcDkNsId || selectedRoteFeature.nsId === RfdDkNsId) {
			// Checked in route
			route = await this.adminRouteService.getCheckedInRouteByLinkSequence(
				selectedRoteFeature.featureTypeId, 
				linkSequence.oid, 
				linkSequence.tvId,
				linkSequence.fromMeasure, 
				linkSequence.toMeasure, 
				this.applicationRepo.viewDate);
		}
		else {
			// Checked out route
			route = await this.adminRouteService.getCheckedOutRouteByLinkSequence(
				selectedRoteFeature.featureTypeId, 
				linkSequence.oid, 
				linkSequence.tvId,
				linkSequence.fromMeasure, 
				linkSequence.toMeasure, 
				this.applicationRepo.viewDate);
		}

		this.eventAggregator.publish(AdminRouteEventItemType.SET_ROUTE, route);
	}

	private layersUpdated(layers: string[]): void {

		if (this.featureGroups) {
			layers.forEach(layer => {
				for (let index = this.featureGroups.length-1; index >= 0; index--) {
					if (this.featureGroups[index].featureType.LayerName === layer) {
						this.featureGroups.splice(index, 1);
					}

					if (this.featureGroups.length == 0) {
						break;
					}
				}
			});

			this.createHaloForFeatureGroups(this.featureGroups);
		}
		else {
			this.deleteHaloFeatures();
		}
		
		this.setAnyFeatures();
	}

	private parseFeatures(features: IFeature[]): void {
		this.featureGroups = [];
		
		// Group features by layer
		if (features && features.length > 0) {
			for (let i = 0; i < features.length; i++) {
				const feature = features[i];
				const featureType = this.applicationRepo.featureTypes.find(x => x.LayerName === feature.LayerId)
				
				let featureGroup: IFeatureGroup = this.featureGroups.find(x => x.featureType.LayerName === featureType.LayerName);
				if (!featureGroup) {
					const phenomena = this.phenomenas.find(x => x.id == feature.FeatureTypeId && x.nsid == feature.NamespaceId);
	
					featureGroup = { featureType: featureType, phenomena: phenomena, features: [] };
					this.featureGroups.push(featureGroup);
				}
	
				featureGroup.features.push(feature);
			}
		}

		this.createHaloForFeatureGroups(this.featureGroups);

		this.setAnyFeatures();
	}

	private deleteHaloFeatures(): void {
		if (this.selectedFeatureLayer) {
			this.map.removeLayer(this.selectedFeatureLayer);			
		}
	}

	private createHaloForFeatureGroups(featureGroups: IFeatureGroup[]): void {
		const selectedFeatureLayerArray = [];
		featureGroups.forEach(x => {
			
			x.features.forEach(feature => {

				const featureCoordinates = feature.Geometry.map(x => {
					return x.map(y => {
						{ return [y.X, y.Y]; }
					})
				});

				const geojson: Proj4GeoJSONFeature = {
					type: 'Feature',
					geometry: {
						type: 'MultiLineString',
						coordinates: featureCoordinates
					},
					crs: {
						type: 'name',
						properties: {
							name: 'urn:ogc:def:crs:EPSG::3006'
						}
					},
					properties: {
						color: '#ffff00',
						weight: 4
					}
				};
	
				const haloLayer = L.Proj.geoJson(geojson, {
					style: function (feature: any) {
						return {
							color: '#ffff00',
							opacity: 0.8,
							weight: 10
						};
					}
				});
	
				selectedFeatureLayerArray.push(haloLayer);
			});
		});

		if (this.selectedFeatureLayer) {
			this.map.removeLayer(this.selectedFeatureLayer);			
		}

		this.selectedFeatureLayer = L.layerGroup(selectedFeatureLayerArray);
		this.map.addLayer(this.selectedFeatureLayer);
	}
	
	private getSelectedRouteFeature(): IAttributeTableEventData {
		const keys = Object.keys(this.selectedFeatures);
		
		if (keys.length === 0 || keys.length > 1) {
			return null;
		}

		const validFeatureTypes = [ FeatureTypeId.FacitRutt, FeatureTypeId.RekommenderadLed, FeatureTypeId.TimmerLed, FeatureTypeId.TpLed ];
		const selectedFeature = this.selectedFeatures[keys[0]];

		if (validFeatureTypes.indexOf(selectedFeature.featureTypeId) > -1) {
			return selectedFeature;
		}

		return null;
	}

	private setAnyFeatures(): void {
		if (!this.featureGroups || this.featureGroups.length == 0) {
			this.anyFeatures = false;
			this.badgeValue = '';

			return;
		}

		let featureCount = 0;
		this.featureGroups.forEach(x => {
			if (x.features && x.features.length > 0) {
				featureCount += x.features.length;
			}
		});

		if (featureCount > 0) {
			this.anyFeatures = true;
			this.badgeValue = `${featureCount}`;
		}
		else {
			this.anyFeatures = false;
			this.badgeValue = '';
		}
	}
}