import { autoinject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

@autoinject()
export class Alert {
    protected errorMessage: string;
    protected extraInfo: string;
    protected abortText = "Nej";
    protected confirmText = "Ja";
    protected okText = "OK";
    protected isConfirm = false;
    protected isInfo = false;
    protected isSuccess = false;
    protected isError = false;
	protected isQuestion = false;
	protected confirmButtonStyle = "background-color: #A9C50E !important;";

    constructor(private controller: DialogController) {

    }

    public activate(model: AlertModel) {
        this.errorMessage = model.ErrorMessage;
        if (model.ExtraInfo) {
            this.extraInfo = model.ExtraInfo;
        }

        if (model.AbortText) {
            this.abortText = model.AbortText;
        }

        if (model.ConfirmText) {
            this.confirmText = model.ConfirmText;
        }

        if (model.Mode === "confirm") {
            this.isConfirm = true;
        }else if (model.Mode === "info") {
            this.isInfo = true;
        }else if (model.Mode === "success") {
            this.isSuccess = true;
        }else if (model.Mode === "error") {
            this.isError = true;
        }else if (model.Mode === "question") {
            this.isQuestion = true;
		}
		
		if(model.ConfirmButtonColor === "red") {
			this.confirmButtonStyle = "background-color: #E23F37 !important;";
		} else { //green
			this.confirmButtonStyle = "background-color: #A9C50E !important;";
		}
    }
}

export interface AlertModel {
	Mode: "confirm" | "info" | "success" | "error" | "question";
    ErrorMessage?: string;
	ExtraInfo?: string;
	ErrorCode?: string;
    AbortText?: string;
    ConfirmText?: string;
	ConfirmButtonColor?: "green" | "red";
}