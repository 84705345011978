import { bindable, autoinject, observable, Disposable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

import * as L from 'leaflet';

import { LayerGroups } from '../layer-groups';
import { AppAuthService } from 'services/authentication/app-auth-service';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { DataLayerService } from 'services/data-layer/data-layer-service';
import { EnvironmentConfiguration } from 'services/configuration/services/configuration';
import { MapEventType } from 'vv-constants/map-event-type.enum';
import { LayerEventType } from 'vv-constants/layer-event-type.enum';
import { IDataLayer } from 'services/data-layer/models/data-layer.interface';
import { ILayerGroup } from 'services/data-layer/models/layer-group.interface';
import { IExtendedDataLayer } from './models/extended-data-layer.interface';
import { IExtendedLayerGroup } from './models/extended-layer-group.interface';
import { MapConfiguration } from 'services/map-configuration/map-configuration';

@autoinject()
export class LayerGroup {
	
	@bindable
	item: IExtendedLayerGroup;

	@bindable
	parent: LayerGroups;

	protected blockagesEnabled: boolean;
	protected facitsEnabled: boolean;
	protected dynamicsEnabled: boolean;
	protected hasDisabledLayers: boolean;
	protected hasActiveLayers: boolean;
	
	@observable protected currentMapZoom: number;

	private map: L.Map;
	private scales: number[];
	private mapMovedSubscription: Disposable;

	private blockagesChecked: boolean;
	private facitroutesChecked: boolean;
	private dynamicDataChecked: boolean;

	constructor(
		private eventAggregator: EventAggregator, 
		private mapConfiguration: MapConfiguration,
		private appAuthService: AppAuthService,
		private applicationRepo: ApplicationRepository,
		private dataLayerService: DataLayerService,
		private config: EnvironmentConfiguration) {

		// Toggle Blockages
		const toggleConfig = this.config.env.FeatureToggle;
		this.blockagesEnabled = toggleConfig.BlockagesAreEnabled;
		this.facitsEnabled = toggleConfig.FacitsAreEnabled;
		this.dynamicsEnabled = toggleConfig.DynamicsAreEnabled;

		this.scales = this.mapConfiguration.getScales();
	}

	bind(): void {
		// Converting MaxScaleDenom to MaxZoomLevel for layers within group
		this.item.DataLayers.forEach(x => {
			let zoomLevel: number = undefined;
			for (let i = 0; i < this.scales.length; i++) {
				if (this.scales[i] < x.MaxScaleDenom) {
					zoomLevel = i;
					break;
				}
			}

			x.MaxZoomLevel = zoomLevel;
		});
	}

	attached(): void {
		if (!this.map) {
			this.map = this.applicationRepo.map;
		}

		this.mapMovedSubscription = this.eventAggregator.subscribe(MapEventType.MAP_MOVED, () => {
			// Check if checked layers are within configured scale
			this.checkLayersAreWithinScale();
		});
	}

	detached(): void {
		this.mapMovedSubscription.dispose();
	}

	toggleLayer(layer): void {
		layer.expanded = !layer.expanded

		if (!layer.legendUrl) {
			this.getLegendGraphicsUrl(layer);
		}
	}

	layersCheckedChanged(layers: string[], visible: boolean, caller: LayerGroup): void {
		if (caller == this) {
			return;
		}

		const affectedLayers = this.getLayersAffected(layers);
		if (affectedLayers && affectedLayers.length > 0) {
			for (let i = 0; i < affectedLayers.length; i++) {
				affectedLayers[i].Visible = visible;
			}

			this.checkLayersAreWithinScale();
		}
	}

	getCheckedLayersWithinScale(): string[] {

		const validLayers: IExtendedDataLayer[] = [];
		let layer: IExtendedDataLayer;
		for (let i = 0; i < this.item.DataLayers.length; i++) {
			layer = this.item.DataLayers[i];
			if (layer.Visible && !layer.Disabled) {
				validLayers.push(layer);
			}
		}

		return validLayers.map(x => x.OwsLayerName);
	}

	protected zoomToLayer(layer: IExtendedDataLayer): void {
		this.map.setZoom(layer.MaxZoomLevel);
	}

	protected blockageCheckedChanged(evt): void {
		this.blockagesChecked = evt.detail;
		this.checkLayersAreWithinScale();
	}
	protected facitRouteCheckedChanged(evt): void {
		this.facitroutesChecked = evt.detail;
		this.checkLayersAreWithinScale();
	}
	protected dynamicsCheckedChanged(evt): void{
		this.dynamicDataChecked = evt.detail;
		this.checkLayersAreWithinScale();
	}

	protected layerCheckedChanged(evt, layer: IExtendedDataLayer): void {
		const layerIsChecked: boolean = evt.target.checked;
		(layer as any).expanded = layerIsChecked;

		if (!(layer as any).legendUrl) {
			this.getLegendGraphicsUrl(layer);
		}

		if (layerIsChecked) {
			this.eventAggregator.publish(LayerEventType.LAYERS_ADDED, [layer.OwsLayerName]);
		}
		else {
			this.eventAggregator.publish(LayerEventType.LAYERS_REMOVED, [layer.OwsLayerName]);
		}

		this.checkLayersAreWithinScale();

		const matches = this.item.DataLayers.filter(x => x.OwsLayerName === layer.OwsLayerName).map(x => x.OwsLayerName);
		this.parent.layersCheckedChanged(matches, layerIsChecked, this);
	}
	
	protected getLegendGraphicsUrl = (layer: IExtendedDataLayer) => {
		const mapToken =  this.appAuthService.getPrincipal().mapToken;
		(layer as any).legendUrl =  this.dataLayerService.getLegendGraphicUrl(layer.OwsLayerName, mapToken);		
	}

	private checkLayersAreWithinScale(): void {
		if (!this.map) {
			return;
		}

		this.currentMapZoom = this.map.getZoom();
		const currentMapScale = this.scales[this.currentMapZoom];

		let hasDisabledLayers = false;
		let hasActiveLayers = false;
		let layer: IExtendedDataLayer;
		for (let i = 0; i < this.item.DataLayers.length; i++) {
			layer = this.item.DataLayers[i];

			layer.Disabled = currentMapScale > layer.MaxScaleDenom;

			if (layer.Disabled && layer.Visible) {
				hasDisabledLayers = true;
			}
			else if (layer.Visible) {
				hasActiveLayers = true;
			}
		}

		this.hasDisabledLayers = hasDisabledLayers;
		this.hasActiveLayers = hasActiveLayers || this.blockagesChecked || this.facitroutesChecked || this.dynamicDataChecked;
	}

	private getLayersAffected(changedLayers: string[]): IExtendedDataLayer[] {
		const layersAffected: IExtendedDataLayer[] = [];

		for (let i = 0; i < this.item.DataLayers.length; i++) {
			if (changedLayers.some(x => x === this.item.DataLayers[i].OwsLayerName)) {
				layersAffected.push(this.item.DataLayers[i]);
			}
		}

		return layersAffected;
	}
 
}


