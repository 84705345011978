import { children, autoinject, Disposable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

import * as L from 'leaflet';

import { BoxSelect } from 'leaflet-extensions/box-select';
import { DataLayerService } from 'services/data-layer/data-layer-service';
import { AppAuthService } from 'services/authentication/app-auth-service';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { LayerGroup } from './layer-group/layer-group';
import { EnvironmentConfiguration } from 'services/configuration/services/configuration';
import { MapEventType } from 'vv-constants/map-event-type.enum';
import { LayerEventType } from 'vv-constants/layer-event-type.enum';
import { FeatureEventType } from 'vv-constants/feature-event-type.enum';
import { ILayerGroup } from 'services/data-layer/models/layer-group.interface';

@autoinject()
export class LayerGroups {

	@children('layer-group') layerGroups: LayerGroup[];

	protected map: L.Map;
	protected dataLayers: ILayerGroup[];
	protected thisVM = this;
	
	private crs: L.Proj.CRS;
	private selectHandler: any;
	private mapLoadedSubscription: Disposable;
	private mapClickedSubscription: Disposable;
	private layersAddedSubscription: Disposable;
	private layersRemovedSubscription: Disposable;
	private viewDateSubscription: Disposable;

	private wmsLayerConfig: { wmsLayerNames: string[]; wmsLayer: any; viewDate: string } = {
		wmsLayerNames: [],
		wmsLayer: null,
		viewDate: null
	}

	constructor(
		private eventAggregator: EventAggregator,
		private appAuthService: AppAuthService,
		private dataLayerService: DataLayerService, 
		private applicationRepo: ApplicationRepository,
		private config: EnvironmentConfiguration) {

	}

	/**
	 * component is attached to the DOM
	 */
	attached(): void {
		this.map = this.applicationRepo.map;

		if (this.map) {
			this.init();
		}
		else {
			this.mapLoadedSubscription = this.eventAggregator.subscribeOnce(MapEventType.MAP_LOADED, () => {
				this.map = this.applicationRepo.map;

				if (!this.map) {
					throw new Error("Could not get hold of map");
				}

				this.init();
			});
		}

		this.wmsLayerConfig.viewDate = this.applicationRepo.viewDate;
	}

	detached(): void {
		if (this.mapLoadedSubscription) {
			this.mapLoadedSubscription.dispose();
			this.mapLoadedSubscription = undefined;
		}
		this.layerGroups = null;
		if (this.mapClickedSubscription) {
			this.mapClickedSubscription.dispose();
			this.mapClickedSubscription = undefined;
		}

		if (this.layersAddedSubscription) {
			this.layersAddedSubscription.dispose();
			this.layersAddedSubscription = undefined;
		}

		if (this.layersRemovedSubscription) {
			this.layersRemovedSubscription.dispose();
			this.layersRemovedSubscription = undefined;
		}

		if (this.viewDateSubscription) {
			this.viewDateSubscription.dispose();
			this.viewDateSubscription = undefined;
		}

		if (this.map && this.wmsLayerConfig.wmsLayer) {
			this.map.removeLayer(this.wmsLayerConfig.wmsLayer);
		}
	}

	layersCheckedChanged(layers: string[], visible: boolean, caller: LayerGroup): void {
		for (let i = 0; i < this.layerGroups.length; i++) {
			this.layerGroups[i].layersCheckedChanged(layers, visible, caller);
		}
	}

	private init(): void {
		this.crs = this.applicationRepo.mapCrs;

		if (!this.mapClickedSubscription) {
			this.mapClickedSubscription = this.eventAggregator.subscribe(MapEventType.MAP_CLICKED, (latLng: L.LatLng) => {
				this.mapClicked(latLng);
			});
		}

		if (!this.layersAddedSubscription) {
			this.layersAddedSubscription = this.eventAggregator.subscribe(LayerEventType.LAYERS_ADDED, (layers: string[]) => {
				this.addWmsLayers(layers);
			});
		}

		if (!this.layersRemovedSubscription) {
			this.layersRemovedSubscription = this.eventAggregator.subscribe(LayerEventType.LAYERS_REMOVED, (layers: string[]) => {
				this.removeWmsLayers(layers);
			});
		}

		if (!this.viewDateSubscription) {
			this.viewDateSubscription = this.eventAggregator.subscribe(LayerEventType.VIEW_DATE_CHANGED, (viewDate: string) => {
				this.wmsLayerConfig.viewDate = viewDate;
				this.reloadWmsLayer();
			});
		}

		this.dataLayerService.getDataLayers().then((x: ILayerGroup[]) => {
			this.dataLayers = x;
		});

		this.addBoxSelectControl();
	}

	private async mapClicked(latLng: L.LatLng): Promise<void> {
		if (!this.hasCheckedLayers()) {
			this.eventAggregator.publish(FeatureEventType.NO_FEATURE_IDENTIFIED);
			return;
		}

		const layers = this.getCheckedLayerNamesWithinScale();
		if (!layers) {
			this.eventAggregator.publish(FeatureEventType.NO_FEATURE_IDENTIFIED);
			return;
		}

		this.eventAggregator.publish(FeatureEventType.FEATURE_IDENTIFIED_STARTED);

		const pnt = this.crs.project(latLng);

		const buffer = this.getFeatureClickBufferMeters(latLng, 10);

		try {
			const features = await this.dataLayerService.getFeaturesAtLocation(pnt.x, pnt.y, layers, this.applicationRepo.viewDate, buffer);
			this.eventAggregator.publish(FeatureEventType.FEATURE_IDENTIFIED, {features: features});
		}
		catch(error) {
			this.eventAggregator.publish(FeatureEventType.FEATURE_IDENTIFIED, {features: null});
		}
	}

	private addWmsLayers(layers: string[]): void {

		if (!layers || layers.length == 0) {
			// Nothing to add
			return;
		}

		// Remove layers that matches if any -> append last in array
		for (let i = 0; i < layers.length; i++) {
			const idx = this.wmsLayerConfig.wmsLayerNames.indexOf(layers[i]);
			if (idx > -1) {
				this.wmsLayerConfig.wmsLayerNames.splice(idx, 1);
			}
		}

		// Add new layers last in array
		this.wmsLayerConfig.wmsLayerNames = this.wmsLayerConfig.wmsLayerNames.concat(layers);

		this.reloadWmsLayer();
	}

	private removeWmsLayers(layers: string[]): void {

		if (!layers || layers.length == 0) {
			// Nothing to remove
			return;
		}

		for (let i = 0; i < layers.length; i++) {
			const idx = this.wmsLayerConfig.wmsLayerNames.indexOf(layers[i]);
			if (idx > -1) {
				this.wmsLayerConfig.wmsLayerNames.splice(idx, 1);
			}
		}

		this.reloadWmsLayer();
	}

	private reloadWmsLayer(): void {
		if (this.wmsLayerConfig.wmsLayer) {
			// Always remove layer
			this.map.removeLayer(this.wmsLayerConfig.wmsLayer);
		}

		if (this.wmsLayerConfig.wmsLayerNames.length === 0) {
			// No layers left
			return;
		}

		const layersParameter = this.wmsLayerConfig.wmsLayerNames.join();
		const mapSubDomains: number[] = this.config.env.MapSubDomains;
		const url: string = this.config.env.WmsBaseUrl + "/DataLayer";
		const mapToken =  this.appAuthService.getPrincipal().mapToken;

		this.wmsLayerConfig.wmsLayer = L.tileLayer.wms(url, {
				layers: layersParameter,
				format: 'image/png',
				TRANSPARENT: 'TRUE',
				P_SEARCHDATE: this.wmsLayerConfig.viewDate,
				subdomains: mapSubDomains,
				token: mapToken,
				tileSize: 512
			} as any
		);

		this.map.addLayer(this.wmsLayerConfig.wmsLayer);
		this.wmsLayerConfig.wmsLayer.bringToFront();
	}

	private addBoxSelectControl(): void {
		if (!this.selectHandler) {
			// this.selectHandler = BoxSelect(this.map); // boxSelect(this.map);
			// this.map.addHandler("box-select", () => {
			// 	return this.selectHandler;
			// });

			// this.selectHandler.enable();
            this.map.addHandler("box-select", BoxSelect); // TODO: verify upgrade.
            this.selectHandler = true;
			this.map.on("boxselectend", (e: any) => {
				if (!this.hasCheckedLayers()) {
					this.eventAggregator.publish(FeatureEventType.NO_FEATURE_IDENTIFIED);
					return;
				}
		
				const layers = this.getCheckedLayerNamesWithinScale();
				if (!layers) {
					this.eventAggregator.publish(FeatureEventType.NO_FEATURE_IDENTIFIED);
					return;
				}

				this.eventAggregator.publish(FeatureEventType.FEATURE_IDENTIFIED_STARTED);

				const bounds: L.LatLngBounds = e.boxSelectBounds;
				const southWest = this.crs.project(bounds.getSouthWest());
				const northEast = this.crs.project(bounds.getNorthEast());

				this.dataLayerService.getIntersectingFeatures(southWest.x, southWest.y, northEast.x, northEast.y, layers, this.applicationRepo.viewDate).then(data => {
					this.eventAggregator.publish(FeatureEventType.FEATURE_IDENTIFIED, {features: data});
				});
			});
		}
	}

	private hasCheckedLayers(): boolean {
		return this.wmsLayerConfig.wmsLayerNames != null && this.wmsLayerConfig.wmsLayerNames.length > 0;
	}

	/**
	 * Get all checked layers that currently are within configured scale
	 */
	private getCheckedLayerNamesWithinScale(): string {
		let layers: string[] = [];

		for (let i = 0; i < this.layerGroups.length; i++) {
			layers = layers.concat(this.layerGroups[i].getCheckedLayersWithinScale());
		}

		const uniqueLayers = layers.filter((value, index, layers) => {
			return layers.indexOf(value) === index;
		});
		
		const layerNames = uniqueLayers.join();

		return layerNames;
	}

	/**
	 * Converts pixels to meter for the current zoom level of map
	 * @param latLng The latLng to buffer	 
	 * @param bufferPixels Number of pixels to buffer (defaults to 10 pixels)
	 */
	private getFeatureClickBufferMeters(latLng: L.LatLng, bufferPixels = 10): number {

		const pointC = this.map.latLngToContainerPoint(latLng);
		const pointX = [pointC.x + bufferPixels, pointC.y];
		const pointY = [pointC.x, pointC.y + bufferPixels]; 
		
		const latLngC = this.map.containerPointToLatLng(pointC);
		const latLngX = this.map.containerPointToLatLng(pointX as any);
		const latLngY = this.map.containerPointToLatLng(pointY as any);
		
		const distanceX = latLngC.distanceTo(latLngX);
		const distanceY = latLngC.distanceTo(latLngY);
		const buffer = Math.max(distanceX, distanceY);

		return buffer;
	}
}