import { bindable, autoinject} from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import * as L from 'leaflet';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { Proj4GeoJSONFeature } from 'proj4leaflet';
import { AttributeTableEventType } from './models/attribute-table-event-type.enum';
import { IAttributeTableEventData } from './models/attribute-table-event-data.interface';
import { IHeaderRow } from './models/header-row.interface';
import { IHeaderCol } from './models/header-col.interface';
import { IExtendedPhenomena } from './models/extended-phenomena.interface';
import { IFeatureRow } from './models/feature-row.interface';
import { IFeatureGroup } from '../models/feature-group.interface';

@autoinject()
export class AttributeTable {
    @bindable public featureGroup: IFeatureGroup;
    @bindable public multiSelect;

    protected headerRows: IHeaderRow[];
    protected featureRows: IFeatureRow[] = [];
    protected selectedRowHex = "#ccc";
    protected selectedLineHex = "#A0316D";

    private map: L.Map;
	private selectedFeatureLayer: L.Layer;

    constructor(private applicationRepo: ApplicationRepository, private eventAggregator: EventAggregator) {
        this.map = this.applicationRepo.map;
    }

    bind() {
        // Parse phenomena to header table
        this.phenomenaToRowsAndCols();
        this.featureToFeatureRows();
    }

    /**
     * Local tear down
     */
    unbind() {
        if (this.selectedFeatureLayer) {
            this.map.removeLayer(this.selectedFeatureLayer);
        }
    }

    protected toggleFeature(row: IFeatureRow) {
        const doSelect = row.selected ? false : true;
        if (!this.multiSelect) {
            this.unselectFeatures();
        }

        row.selected = doSelect;
        this.highlightSelectedFeatures();
        this.triggerSelectEvent();
    }

    private highlightSelectedFeatures() {
        const selectedFeatureLayerArray = [];
        this.featureRows.forEach(featureRow => {
            if (!featureRow.selected) {
                return;
            }

            const featureCoordinates = featureRow.feature.Geometry.map(x => {
                return x.map(y => {
                    { return [y.X, y.Y]; }
                })
            });

            const geojson: Proj4GeoJSONFeature = {
                type: 'Feature',
                geometry: {
                    type: 'MultiLineString',
                    coordinates: featureCoordinates
                },
                properties: {

                },
                crs: {
                    type: 'name',
                    properties: {
                        name: 'urn:ogc:def:crs:EPSG::3006'
                    }
                }
            };

            const selectedLayer = L.Proj.geoJson(geojson, {
                style: (feature: any) => {
                    return {
                        color: this.selectedLineHex,
                        opacity: 1,
                        weight: 3
                    };
                }
            });

            selectedFeatureLayerArray.push(selectedLayer);
        });

        if (this.selectedFeatureLayer) {
            this.map.removeLayer(this.selectedFeatureLayer);
        }

        this.selectedFeatureLayer = L.layerGroup(selectedFeatureLayerArray);
        this.map.addLayer(this.selectedFeatureLayer);
    }

    private unselectFeatures() {
        this.featureRows.forEach(featureRow => {
            featureRow.selected = false;
        });
    }

    private featureToFeatureRows() {
        this.featureGroup.features.forEach(feature => {
            const featureRow: IFeatureRow = {feature, selected: false} as IFeatureRow
            this.featureRows.push(featureRow);
        });
    }

    /**
     * Sets dimensions on the object (depth, width, h_level) 
     */
    af = (aJ: IExtendedPhenomena, aI, aK) => {
        if (aI) {
            aI.h_level = aK
        }
        if (aK > aJ.depth) {
            aJ.depth = aK
        }
        let aH: number
        let aG: number;
        if (aI && aI["type"] === "composite") {
            aH = 0,
            aG = aI.attribute.length;
            for (aH; aH < aG; aH++) {
                this.af(aJ, aI.attribute[aH], aK + 1)
            }
        } else {
            if (aI && aI["type"] === "simple") {
                aJ.width += 1
            } else {
                if (!aI) {
                    aH = 0;
                    aG = 0;
                    if (aJ.attribute) {
                        aG = aJ.attribute.length;
                    }

                    for (aH; aH < aG; aH++) {
                        this.af(aJ, aJ.attribute[aH], aK + 1)
                    }
                }
            }
        }
    };

    X = (aJ, aI) => {
        if (aI["type"] === "composite") {
            let aH = 0;
            const aG = aI.attribute.length;
            for (aH; aH < aG; aH++) {
                this.X(aJ, aI.attribute[aH])
            }
        } else {
            aJ.leafs += 1
        }
    };

    private phenomenaToRowsAndCols = () => {
        const phenomena: IExtendedPhenomena = this.featureGroup.phenomena as IExtendedPhenomena;

        phenomena.depth = 0;
        phenomena.width = 0;
        this.af(phenomena, null, 0);

        const aO: any[] = [phenomena];
        let tempPhenomena: IExtendedPhenomena;

        const aI = [];
        
        while (aO.length !== 0) {
            tempPhenomena = aO.splice(0, 1)[0];
            if (tempPhenomena !== phenomena) {
                aI.push(tempPhenomena)
            }
            if (tempPhenomena === phenomena || (tempPhenomena["type"] && tempPhenomena["type"] === "composite")) {
                if (tempPhenomena.showSpecified && !tempPhenomena.show) {
                    // This attribute is not configured to be shown
                    continue;
                }

                if (tempPhenomena.attribute) {
                    for (let i = 0; i < tempPhenomena.attribute.length; i++) {
                        if (tempPhenomena.attribute[i].showSpecified && !tempPhenomena.attribute[i].show) {
                            // This attribute is not configured to be shown
                            continue;
                        }
    
                        aO.push(tempPhenomena.attribute[i])
                    }
                }
            }
        }

        const rows: IHeaderRow[] = [];
        let row: IHeaderRow;
        let cell: IHeaderCol;

        let aH = -1, aK, aJ;
        let aN = 0;
        const aS = aI.length;
        for (aN; aN < aS; aN++) {
            aK = aI[aN];
            aJ = {
                leafs: 0
            };
            this.X(aJ, aK);

            if (aK.h_level !== aH) {
                aH = aK.h_level;
                row = { cols: []};
                rows.push(row);
            }

            cell = { colspan: aJ.leafs, text: aK["name"] };

            if (aK["unit"]) {
                cell.text += " (" + aK["unit"] + ")"
            }

            if (aK["type"] === "simple") {
                cell.rowspan = phenomena.depth - aK.h_level + 1
            }
            else {
                cell.rowspan = 1;
            }

            row.cols.push(cell);
        }

        this.headerRows = rows;
    }

    private triggerSelectEvent(): void {
        const eventData: IAttributeTableEventData = {
            featureTypeId: this.featureGroup.phenomena.id,
            nsId: this.featureGroup.phenomena.nsid,
            layerName: this.featureGroup.featureType.LayerName,
            linkSequences: this.featureRows.filter(x => x.selected).map(x => {
                return {
                    oid: x.feature.RouteId,
                    tvId: x.feature.TvId,
                    fromMeasure: x.feature.FromMeasure,
                    toMeasure: x.feature.ToMeasure
                }
            })
        };

        this.eventAggregator.publish(AttributeTableEventType.FEATURES_SELECTED, eventData);
    }
}
