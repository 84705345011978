// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attribute-table {
    font-size: 12px;
}

.attribute-table tr td {
    border: 1px solid black;
    padding: 2px;
}

.attribute-table tr.header-row {
    font-weight: bold;
    background-color: #eee;
}

.attribute-table tr.feature-row {
    cursor: pointer;
}

.attribute-table {
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/components/common/feature-info/attribute-table/attribute-table.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,2BAA2B;IAC3B,yBAAyB;IACzB,iBAAiB;AACrB","sourcesContent":[".attribute-table {\r\n    font-size: 12px;\r\n}\r\n\r\n.attribute-table tr td {\r\n    border: 1px solid black;\r\n    padding: 2px;\r\n}\r\n\r\n.attribute-table tr.header-row {\r\n    font-weight: bold;\r\n    background-color: #eee;\r\n}\r\n\r\n.attribute-table tr.feature-row {\r\n    cursor: pointer;\r\n}\r\n\r\n.attribute-table {\r\n    -moz-user-select: -moz-none;\r\n    -webkit-user-select: none;\r\n    user-select: none;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
