import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import * as L from 'leaflet';
import { StorageSites } from '../storage-sites';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { IStorageSite } from 'components/common/layer-picker/layer-groups/storage-sites/models/storage-site.interface';
import { IStorageSiteContainer } from '../models/storage-site-container.interface';
import { StorageLayer } from '../models/storage-layer.model';
import { MapConfiguration } from 'services/map-configuration/map-configuration';
import { IconService } from 'services/assets/services/icon.service';

@autoinject()
export class StorageSite {

	@bindable
	parent: StorageSites;
	@bindable
	item: IStorageSiteContainer;
	
	private crs: L.Proj.CRS
	protected checked = false;
	protected indeterminate = false;
	protected title: string;
	private map: L.Map;
	siteMarkerArray: StorageLayer[] = [];

	constructor(
		private applicationRepo: ApplicationRepository,
		private mapConfiguration: MapConfiguration,
		private iconService: IconService) {
		this.map = this.applicationRepo.map;
		this.crs = this.mapConfiguration.getMapCrs().crs;
	}
	bind(): void {
		this.title = `${this.item.name} (${this.item.id})`;
		this.checked = true;
		this.addAllLayers();
		this.showAllLayers();
	}

	unbind(): void {
		this.removeAllLayers();
		this.checked = false;
		this.siteMarkerArray = [];
	}


	@computedFrom('items')
	protected get hasStorageSites(): boolean {
		return this.item.storages && this.item.storages.length > 0;
	}

	protected close() {
        this.removeAllLayers();
        this.checked = false;
        this.indeterminate = false;
        this.parent.deleteItem(this.item.id);
    }

	private addAllLayers(): void {
		this.item.storages.forEach(layer => {
			this.addLayer(layer);
			(layer as any).checked = true;
		});

	}

	private addLayer(storage: IStorageSite): void {
		const startLatlng = this.crs.unproject({ x: storage.X, y: storage.Y } as any);
		const storageSiteIcons = this.iconService.getIconStorageSiteMap();
		const sitesIcon = L.icon({
			iconUrl: storageSiteIcons.get(this.item.type),
			iconSize: [20, 19]
		});

		const siteMarker = L.marker(startLatlng, { icon: sitesIcon });
		siteMarker.bindPopup(this.getPopUpForMarker(storage, siteMarker));

		const label = storage.Name;
		siteMarker.bindTooltip(label, { permanent: true, direction: 'right', interactive: true }).closeTooltip();

		this.siteMarkerArray.push({id: storage.Id, marker: siteMarker});
	}

	private removeAllLayers(): void {
		if (this.siteMarkerArray) {
            this.siteMarkerArray.forEach(layer => {
                this.removeLayer(layer.id);
				const feature = this.item.storages.find(x => x.Id == layer.id);
				(feature as any).checked = false;
            });

            this.siteMarkerArray = [];
        }
	}
	private removeLayer(id: string): void {
		this.siteMarkerArray.forEach(layer => {
			if(layer.id == id){
				this.applicationRepo.map.removeLayer(layer.marker);
				this.siteMarkerArray = this.siteMarkerArray.filter(x => x.id != id);
			}
		});
	}

	protected layerGroupCheckedChanged(event: any): void {
		if (this.checked) {
			this.removeAllLayers();
			this.addAllLayers();
			this.showAllLayers();
		}
		else {
			this.removeAllLayers();
		}

		this.indeterminate = false;
	}

	protected layerCheckedChanged(evt: any, id: string): void {
		if (this.siteMarkerArray.find(x => x.id == id)) {
			this.removeLayer(id);
		}
		else {
			const layer = this.item.storages.find(x => x.Id == id);
			this.addLayer(layer);
			this.showLayer(id);
		}
		const checkedCount = this.item.storages.filter(x => (x as any).checked).length;
        this.checked = checkedCount > 0;
        this.indeterminate = checkedCount > 0 && checkedCount < this.item.storages.length;
	}
	showAllLayers(){
		this.siteMarkerArray.forEach(layer =>{
			this.applicationRepo.map.addLayer(layer.marker);
		});
	}
	showLayer(id){
		this.siteMarkerArray.forEach(layer =>{
			if(layer.id == id){
				this.applicationRepo.map.addLayer(layer.marker);
			}
		});
	}

	zoomToStorage($event, id:string){
		console.log("Klick");
		$event.stopPropagation();
		this.siteMarkerArray.forEach(layer =>{
			if(layer.id == id){
				this.map.fitBounds(layer.marker.getLatLng().toBounds(5));
			}
		});
	}

	getPopUpForMarker(storage: IStorageSite, marker: L.Marker) {
		const contatiner = L.DomUtil.create('div', 'postIt');
		const closeBtn = L.DomUtil.create('span', 'close-postIt');
		closeBtn.innerText = '-';
		closeBtn.title = 'Fäll ihop information';
		closeBtn.onclick = () => {
			marker.closePopup();
		};
		const rubrik = L.DomUtil.create('label', 'label-postIt pull-left');
		rubrik.innerText = "Lagerplats:";
		const label = L.DomUtil.create('label', 'label-name pull-left');
		label.innerText = storage.Name;


		const site = L.DomUtil.create('p', 'p-postIt');
		site.innerHTML = "Plats: " + storage.SiteId;


		const x = L.DomUtil.create('p', 'p-postIt');
		x.innerHTML = "X: " + storage.X;

		const y = L.DomUtil.create('p', 'p-postIt');
		y.innerHTML = "Y: " + storage.Y;

		const distance = L.DomUtil.create('p', 'p-postIt');
		distance.innerHTML = "Avstånd: " + storage.Distance;

		contatiner.appendChild(closeBtn);
		contatiner.appendChild(rubrik);
		contatiner.appendChild(label);
		contatiner.appendChild(site);
		contatiner.appendChild(x);
		contatiner.appendChild(y);
		contatiner.appendChild(distance);

		const popup = L.popup({
			maxHeight: 500,
			maxWidth: 360,
			minWidth: 0,
			autoPan: false,
			closeOnClick: false,
			autoClose: false,
			closeButton: false
		}).setContent(contatiner);

		return popup;
	}

	
}