import { autoinject, observable, computedFrom, Disposable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import * as L from 'leaflet';
import { DataLayerService } from 'services/data-layer/data-layer-service';
import { SearchPopup } from 'components/common/popups/search-popup';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { EnvironmentConfiguration } from 'services/configuration/services/configuration';
import { MapEventType } from 'vv-constants/map-event-type.enum';
import { IMapRoutePoint } from 'services/application-repository/models/map-route-point.interface';
import { MapConfiguration } from 'services/map-configuration/map-configuration';
import { IconService } from 'services/assets/services/icon.service';
import { IconType } from 'services/assets/enums/icon-type.enum';

@autoinject
export class Sites {
	protected title = 'Mottagningsplatser';
	protected hasDisabledLayers: boolean;
	protected hasActiveLayers: boolean;
	protected isViol3Mode: boolean;
	protected maxZoomLevel: number;

	@observable protected currentMapZoom: number;

	private map: L.Map;
	private crs: L.Proj.CRS
	private scales: number[];
	private siteInfoMaxScale: number;
	private sitesMaxScale: number;

	private siteMarkers: Map<number, SiteLayer>;
	private siteLayer: L.MarkerClusterGroup;
	private sitesLayerAdded: boolean;
	
	private mapLoadedSubscription: Disposable;
	private mapMovedSubscription: Disposable;

	@computedFrom('siteMarkers')
	get siteLayers(): SiteLayer[] {
		if (!this.siteMarkers) {
			return null;
		}

		return Array.from(this.siteMarkers.values());
	}

	constructor(
		private dataLayerService: DataLayerService, 
		private eventAggregator: EventAggregator,
		private mapConfiguration: MapConfiguration,
		private searchPopup: SearchPopup,
		private applicationRepo: ApplicationRepository,
		private config: EnvironmentConfiguration,
		private iconService: IconService) {

		this.scales = this.mapConfiguration.getScales();
		this.siteMarkers = new Map<number, SiteLayer>();
		
		const sitesConfig = this.config.env.Sites;
		this.sitesMaxScale = sitesConfig.MaxScaleDenom;
		this.siteInfoMaxScale = sitesConfig.MaxScaleDenomSiteInfo;
		
		const toggleConfig = this.config.env.FeatureToggle;
		this.isViol3Mode = toggleConfig.IsViol3;

		let maxZoomLevel: number = undefined;
		// Converting MaxScaleDenominator to MaxZoomLevel
		for (let i = 0; i < this.scales.length; i++) {
			if (this.scales[i] < this.sitesMaxScale) {
				maxZoomLevel = i;
				break;
			}
		}

		if (this.isViol3Mode) {
			this.title = 'Platser';
			
			this.siteMarkers = new Map<number, SiteLayer>([
				[1, { type: 1, name: "Mottagningsplats", checked: false, markers: [], legendUrl: this.iconService.getIcon(IconType.IconSite1), maxZoomLevel: maxZoomLevel }],
				[2, { type: 2, name: "Mätplats", checked: false, markers: [], legendUrl: this.iconService.getIcon(IconType.IconSite2), maxZoomLevel: maxZoomLevel }],
				[3, { type: 3, name: "Hämtplats", checked: false, markers: [], legendUrl:this.iconService.getIcon(IconType.IconSite3), maxZoomLevel: maxZoomLevel }],
				[4, { type: 4, name: "Omlastningsplats", checked: false, markers: [], legendUrl: this.iconService.getIcon(IconType.IconSite4), maxZoomLevel: maxZoomLevel }]
			]);
			
		}
		else {
			this.title = 'Mottagningsplatser';
			this.siteMarkers = new Map<number, SiteLayer>([
				[1, { type: 1, name: "Mottagningsplatser", checked: false, markers: [], legendUrl: this.iconService.getIcon(IconType.IconSite1), maxZoomLevel: maxZoomLevel }]
			]);
		}
		this.crs = this.mapConfiguration.getMapCrs().crs;
	}

	attached(): void {
		this.map = this.applicationRepo.map;
		
		if (this.map) {
			this.init();
		}
		else {
			this.mapLoadedSubscription = this.eventAggregator.subscribeOnce(MapEventType.MAP_LOADED, () => {
				this.map = this.applicationRepo.map;

				if (!this.map) {
					throw new Error("Could not get hold of map");
				}

				this.init();
			});
		}
	}

	detached(): void {
		if (this.mapLoadedSubscription) {
			this.mapLoadedSubscription.dispose();
			this.mapLoadedSubscription = undefined;
		}

		if (this.mapMovedSubscription) {
			this.mapMovedSubscription.dispose();
			this.mapMovedSubscription = undefined;
		}

		if (this.map && this.siteLayer) {
			this.siteLayer.removeFrom(this.map);
			this.siteLayer = undefined;
		}

		this.siteMarkers = null;
	}

	protected toggleLayer(layer: SiteLayer): void {
		layer.expanded = !layer.expanded
	}

	protected zoomToLayer(layer: SiteLayer): void {
		this.map.setZoom(layer.maxZoomLevel);
	}

	protected async layerCheckedChanged(evt, layer: SiteLayer): Promise<void> {
		const layerIsChecked: boolean = evt.target.checked;
		layer.expanded = layerIsChecked;

		if (layerIsChecked) {
			await this.loadSitesForType(layer.type);
		}

		this.siteLayer.clearLayers();
		this.sitesLayerAdded = false;
		this.refreshSites()
	}

	private refreshSites() {
		if (!this.map) {
			return;
		}

		if (!this.siteLayer) {
			this.createSitesLayer();
		}

		this.currentMapZoom = this.map.getZoom();
		const currentMapScale = this.scales[this.currentMapZoom];
		this.hasDisabledLayers = currentMapScale > this.sitesMaxScale;
		this.hasActiveLayers = (this.hasCheckedLayers() && !this.hasDisabledLayers);

		if (this.hasCheckedLayers() && !this.hasDisabledLayers && !this.sitesLayerAdded) {
			this.sitesLayerAdded = true;
			this.addCheckedLayers();
		}
		else if (this.sitesLayerAdded && (this.hasDisabledLayers || !this.hasCheckedLayers())) {
			this.sitesLayerAdded = false;
			if (this.siteLayer) {
				this.siteLayer.clearLayers();
			}
		}

		this.showOrHideSiteTooltips();
	}
	
	private init(): void {
		if (this.siteLayer) {
			return;
		}

		this.mapMovedSubscription = this.eventAggregator.subscribe(MapEventType.MAP_MOVED, () => {
			this.refreshSites();
		});

		this.createSitesLayer();
	}
	
	private showOrHideSiteTooltips() {

		if (!this.map || !this.siteLayer) {
			return;
		}

		const zoomLevel = this.map.getZoom();
		const currentScale = this.scales[zoomLevel];
		const sitesLabelEnabled = currentScale <= this.siteInfoMaxScale;

		if (!sitesLabelEnabled || !this.hasCheckedLayers()) {
			this.siteLayer.eachLayer(x => {
				if (x.isTooltipOpen()) {
					x.closeTooltip();
				}
			});

			return;
		}

		const mapBounds = this.map.getBounds();
		
		this.siteLayer.eachLayer(x => {
			if (x.isTooltipOpen()) {
				x.closeTooltip();
			}

			// Only show tooltips for those layers that are within current map bounds
			if (mapBounds.contains((x as any).getLatLng())) { 
				x.openTooltip();

				x.on('mouseover', () => {
					const tooltip = x.getTooltip();
					tooltip.bringToFront();
				});
			}
		});
	}
	
	private createSitesLayer(): void {
		if (!this.map) {
			return;
		}

		if (!this.siteLayer) {
			this.siteLayer = new (L as any).MarkerClusterGroup({ 
				spiderfyDistanceMultiplier: 3, 
				maxClusterRadius: 32, 
				showCoverageOnHover: false, 
				zoomToBoundsOnClick: false,
				iconCreateFunction: (cluster) => {
					const markers = cluster.getAllChildMarkers();

					const count = markers.length;
					const content = `<div><span>${count}</span></div>`;

					if (count > 5) {
						return L.divIcon( ({ html: content, className: "site-cluster-large", iconSize: L.point(32, 30) } as any));
					}
					else {
						return L.divIcon( ({ html: content, className: "site-cluster", iconSize: L.point(24, 22) } as any));
					}
				}
			}
		);

		this.siteLayer.on('clusterclick', (a: any) => {
			const markers = a.layer.getAllChildMarkers()
			if (markers && markers.length <= 10) {
				// Only spiderfy when 10 or less in cluster
				a.layer.spiderfy();
			}

			return;
		});
		this.siteLayer.addTo(this.map);
		}
	}
	
	/**
	 * Fetches sites from service for given site type and converts to markers
	 * @param siteType 
	 */
	private async loadSitesForType(siteType: number): Promise<void> {
		if (!this.siteMarkers.has(siteType)) {
			return;
		}

		const currentSiteLayer = this.siteMarkers.get(siteType);
		if (currentSiteLayer.dataLoaded) {
			return;
		}
		
		try {
			const siteData: IMapRoutePoint[] = await this.dataLayerService.getSites(siteType, this.crs);
			const siteMarkerArray: L.Marker[] = [];
			siteData.forEach(x => {
				const startLatlng = this.crs.unproject({ x: x.easting, y: x.northing } as any);
				const sitesIcon = L.icon({
					
					iconUrl: `./assets/images/svg/icon-site-${siteType}.svg`,
					iconSize: [16, 15]
				});
	
				const siteMarker = L.marker(startLatlng, { icon: sitesIcon })
					.bindPopup(this.searchPopup.getSearchPopup(x));
	
				siteMarker.on("click", (e) => {
					const popup = e.target.getPopup();
					const content = this.searchPopup.getSearchPopupContent(x);
					popup.setContent(content);
					popup.update();
				});
	
				const label = x.name +'<br />'+ x.id;
				siteMarker.bindTooltip(label, { permanent: true, direction: 'right', interactive: true }).closeTooltip();
	
				siteMarkerArray.push(siteMarker);
			});
	
			currentSiteLayer.markers = siteMarkerArray;
			currentSiteLayer.dataLoaded = true;
		}
		catch (error) {
			console.log(error);
		}
	}

	/**
	 * Adds layers that are marked as checked to the layer group (cluster)
	 */
	private addCheckedLayers(): void {
		this.siteMarkers.forEach(x => {
			if (x.checked) {
				this.siteLayer.addLayers(x.markers);
			}
		});
	}

	/**
	 * Checks if there are any site layers marked as checked
	 */
	private hasCheckedLayers(): boolean {
		return Array.from(this.siteMarkers.values()).some(x => x.checked);
	}
}


